const logorowData = [
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/To-Increase%20Logo-1.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Visma-Circle-logo-PNG.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Ism-logo.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Drukwerkdeal-logo.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Phibro-Logo-PNG.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/o2o-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Adama-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Onewelcome-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/CaseWare-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Nextmune-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Gripp-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Clevr-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/To-Increase%20Logo-1.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Visma-Circle-logo-PNG.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Ism-logo.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Drukwerkdeal-logo.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Phibro-Logo-PNG.png',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/o2o-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Adama-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Onewelcome-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/CaseWare-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Nextmune-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Gripp-logo.svg',
    'https://www.brightdigital.com/hubfs/Bright%20Digital%20website/Client%20logos/Clevr-logo.svg',
];

export default logorowData;