import React from 'react';

const ClipPathLogo = ({ cl, type }) => (
  <svg className={cl} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 625.7 401.6" style={{ enableBackground: 'new 0 0 625.7 401.6' }} xmlSpace="preserve">
    <defs>
      <clipPath id={`bb_logo_clippath--${type}`}>
        <path className={`${cl}__shape`}
          d="M236.3,173.7l-1,1.2c-0.5,0.6-1.9,2-3.9,3.9c-7.5,7.3-23,22.6-26.4,29.7c-2.2,4.6-2.9,7.2-3.1,11.6
              c-0.2,2.4,0.3,4.3,1.4,5.6c1.4,1.6,3.9,2.4,7.3,2.4c2.8,0,5.4-0.6,6.3-0.7c12.8-2.7,23-9.7,33.1-17.2c3.4-2.6,6.7-5.4,9.9-8.1
              c0.9-0.8,1.8-1.5,2.7-2.3l1.5-1.3l-0.5,1.9c0,0.1-2.3,8.1-3.2,11.7v0.2c-0.3,1.2,0,2.1,0.4,2.6c0.6,0.9,1.5,1.3,2.2,1.4
              c1.3,0.1,2.8-0.8,4.2-1.7c0.1-0.1,0.4-0.2,0.7-0.4c7.3-4.1,10.4-7.8,9.3-11.1c-1-2.9,0.3-5.3,1.7-7.8l0.3-0.5c0,0,3.6-6.6,5.2-9.4
              c1.7-3,3.5-6.6,5.2-9.7l0.9-1.6c0.7-1.3,0.4-3.3-0.6-4.5c-0.7-0.9-3.1-2-5.6-2c-2.1,0-3.9,0.7-5.4,2.2c0,0-17.1,17.7-21.1,21.9
              c-8.4,8.8-18.6,14.4-28.4,19.1c-1.9,0.9-4.6,2.1-6.9,2.1l0,0c-1.5,0-2.6-0.5-3.3-1.6c-3.1-4.9,7.4-15.3,13.1-20.9
              c0.9-0.8,1.6-1.5,2-2c1.4-1.5,4.5-4.6,7.5-7.6c3.2-3.2,6.5-6.5,7.2-7.3c1.8-2.1,3.7-4.2,5.1-6.4c1.1-1.7,1.2-3.5,0.4-4.6
              c-0.7-1-2-1.5-3.9-1.5s-4,0.4-6.2,0.9c-2.1,0.4-4.1,0.8-5.8,0.8h-0.3c-1.2,0-2.6-0.5-4-0.9c-1.5-0.5-3.1-1-4.7-1.1
              c-3.4-0.1-5.2,0.1-8.3,1.5c-12.8,5.5-28.7,30-29.7,31.6c-0.4,0.7-0.9,2.2-1.1,3.1c-0.1,0.8,0.1,2.9,1.3,3.5c0.9,0.4,1.8,0.7,2.7,0.7
              c4.9,0,9.6-6,11.7-8.5l0.4-0.5c0.5-0.6,11.5-15.2,17.5-18.7l0.2-0.1c0.6-0.2,1.6,0,2.9,0.2c1.5,0.3,3.3,0.6,5.5,0.6
              c0.8,0,1.5,0,2.3-0.1L236.3,173.7z M448,0c-7.9,0-15.4,3.4-22.7,10.5c-7.9,7.5-31.8,30.5-59.5,128.6c-1.6,5.5-3.2,11.1-4.9,17
              c-2.5,8.7-5.1,17.6-7.8,27.3c-5.3,19.1-9.8,36-13.9,51.6c-2.2,8.3-4.5,20.8-3,22.7c0.7,1,1.7,1.5,2.8,1.5c4.9,0,13-9.1,16.9-17.3
              c21.8-45.2,28-46.6,31.3-47.3c0.6-0.1,1.1-0.2,1.7-0.2c4.8,0,7.2,4.9,9.7,10c2.8,5.8,5.7,11.7,12.2,12.3c1.3,0.1,2.5,0.2,3.7,0.2
              c6.9,0,13.3-1.8,19.7-5.5c8.3-4.8,14.8-10.8,20-18.3c2.4-3.5,2.1-7.2,1.1-9.2c-0.6-1.2-1.5-2-2.3-2s-3.8,3-6.4,5.6
              c-6.2,6.1-14.6,14.5-21.3,14.5H425c-5.5-0.2-9.4-2.7-12.5-8.1c-0.1-0.2-0.3-0.5-0.5-0.9c-2.1-4-7.7-14.6-18.9-14.6
              c-6,0-11.2,2.1-15.9,6.5c-5.7,5.3-10.7,11.1-15.3,17.7l-1.9,2.8l0.9-3.3c29.7-113.3,49.6-150.2,53.3-156.5c0.4-0.7,4.2-7.4,9.7-14.1
              c7.7-9.3,14.7-14,20.9-13.7c12,0.5,15.2,11.6,16,18.2c1.6,12.9-1.3,26.1-9.2,41.4c-6.5,12.6-14.7,24.3-24.3,34.7
              c-1.6,1.7-4.8,4.8-8.6,8.4c-8.6,8.2-24.6,23.6-24.1,26.5c0.5,2.8,1.1,3,1.6,3c1,0,3.4,0,10-5.3c12.2-9.8,23.6-21,34.9-34.2
              c18.3-21.3,30.6-48.3,33.2-59.9c3-13.3,2.5-24.2-1.3-34.3c-2.7-6.9-8.2-12.3-14.8-14.6C454.7,0.7,451.3,0,448,0 M293.2,216.6
              c-3.7,0-62.7,27.4-77.7,39c-11.8,9.1-18.5,18.1-22.4,29.8c-5.3,15.8-6.5,31.2-3.7,46.9c4.4,25.4,15.5,44.6,32.8,56.8
              c13.1,9.2,27.4,12.5,35.6,12.5c0.3,0,0.6,0,0.8,0c15.9,0,33.7-3.9,45.1-32.5c8.4-21.5,16.2-69.4,21.9-134.9c0.1-1.2,0.2-2.1,0.2-2.7
              c1.3-14.6,4.6-25.7,6.6-32.4c0.8-2.8,1.4-4.9,1.5-5.9c0.1-1.4,0.1-2.7-1-4c-1-1.1-2.3-1.4-4.5-1.1c-1.3,0.2-2.4,0.6-3.6,1
              c-0.9,0.3-1.8,0.6-2.8,0.8c-5.7,1.3-9.5,0.9-10.9-1.3c-1-1.6-1.2-3.3-0.6-4.9c1.4-3.9,7.2-7.7,16.8-11.1l0.3-0.1
              c0.4-0.2,0.9-0.3,1.3-0.5c2.7-0.9,5.5-1.9,6.3-4c1-2.8,0.7-4.6-0.9-5.8c-0.6-0.4-2.3-1.4-6.1-1.4c-3.7,0-8.2,1-13.2,2.9
              c-17.6,7-20.4,23.7-20.8,28.7c-0.3,3.5,0.9,8.9,3.7,12c1.7,1.8,3.7,2.7,6.1,2.5c1.5-0.1,3.6-0.9,5.2-1.5c1.2-0.4,1.7-0.6,2.1-0.6
              h0.2l0.2,0.1c0.4,0.3,0.5,0.4-2,36.1l-0.5,7.2c-0.8,11.3-2.1,24.1-3.4,37.6c-0.7,7.1-1.4,14.5-2.1,21.6c-1.3,13.8-4.2,29.1-9.1,46.9
              c-3.5,13-14.9,24.9-26.4,27.8c-3.4,0.8-7.1,1.6-11.6,1.6c-13.8,0-26.9-7.8-39-23.1c-5.8-7.4-19.9-32.5-15.7-60.4
              c3.5-23.2,17.8-37.1,29.2-44.6c22.1-14.7,41.3-23.6,52.7-29c6.3-2.9,11.7-5.5,11.9-6.4c0-0.3-0.1-1.7-0.9-2.7
              C294.4,217,293.9,216.7,293.2,216.6 M296.5,145.7c-3.2,0-5.8,0.9-7.6,2.8c-3,3-3,7.6-3,7.6c0.1,0.9,0.3,1.6,0.8,2.1
              c1.3,1.4,4.3,2.1,6.5,2.1c1.1,0,2-0.2,2.7-0.6c3.8-2.2,6.8-4.1,9.4-5.9c1.1-0.7,1.8-2.4,1.6-3.8c-0.2-1.3-3-3.4-8.6-4.2
              C297.7,145.8,297.1,145.7,296.5,145.7 M502.3,75.1c-2.8,0-7,1.4-10.8,7.9c-4.2,7.2-11.1,30.3-19.4,65l-0.4,1.7l-0.4,0.1
              c-7.5,1-13.9,1.9-15.1,4.3c-0.1,0.4-0.1,0.8,0.2,1.1c1.6,2.3,9.6,3.1,12.5,3.2h0.7l-0.2,0.7c-1.2,5.3-2.3,10.1-3.2,14.8
              c-2.6,13-4.8,26.2-7,39c-2.1,8.9-4.2,23.6-1.2,25.2c1,0.5,1.9,0.8,2.8,0.8c2.9,0,4.9-2.6,6.4-4.6l0.5-0.6c5.4-6.8,7.1-22.8,8.7-38.3
              c1.1-10.8,2.3-21.9,4.7-30c0.5-1.7,1.7-7,1.7-7.1l0.1-0.4h0.4c0.6,0,63.6-4.5,75.3-5.2c13.6-0.8,52.5-3.4,58.2-3.9s7.6-1.1,8.6-2.3
              c0.5-1.4,0.5-2.6-1.4-4.3c-2-1.8-4.7-3.5-8-5c-3.2-1.5-6.7-2.9-10.6-3.7c-1.4-0.3-3.2-0.4-5.4-0.4c-10.1,0-26.5,3-36.3,4.7
              c-1.9,0.3-3.6,0.7-5,0.9c-3.6,0.6-5.7,1-7.6,1.4c-3.2,0.6-5.5,1-13.4,2.2c-9.6,1.5-32.8,4.1-52,6.3l-0.9,0.1l0.2-0.8
              c3.7-15.6,7.5-29.8,11.5-43.4c0.1-0.5,3.4-12,9.5-17.6c2.8-2.5,4.3-4.7,3.3-7.5C509.3,77.7,506.3,75.1,502.3,75.1 M198.3,44.8
              c-2.3,4.6-13.6,20.3-25.9,32.4c-0.2,0.2-20.5,20.2-30.7,29.5c-13.2,12-38.5,31.4-41.6,32l-1,0.2l0.4-1c0.1-0.3,0.3-0.9,0.6-1.8
              c1.7-5.1,5.8-17,9.1-22.7c7.7-13.1,19.2-30.3,39.2-47.3c20.4-17.2,35.6-26.4,45.1-27.2c2.9-0.2,4.2,0.9,4.8,1.8
              C199,41.8,199,43.4,198.3,44.8 M150.8,163.7h1c18.4,0,29.4,9,30.4,14c2.8,14.7-46.3,38-65.4,44.3c-20.6,6.9-46.4,10.8-50.8,10.8
              c-0.5,0-0.8-0.1-0.9-0.1l-0.2-0.1l-0.1-0.3c-0.1-0.4-0.3-1.3,13.3-37.2c6.3-16.7,13.5-35.5,13.6-35.7l0.7-0.3
              C104.5,163.5,143.1,163.7,150.8,163.7 M215.2,24.4c-4.1-4.4-11.6-5.4-21.7-2.8c-14.1,3.6-25.1,12.3-35.7,20.6c-1,0.8-2,1.6-3,2.3
              c-16.3,12.7-39.3,41.3-41.6,44.2l0.2,0.6l-1-0.1l-0.2-0.1c-0.9-0.6-3.3-2-5.9-2c-2.7,0-9,1.7-10.7,15.9c-0.1,1.1-2.3,12.9-4,17.1
              c-0.8,1.9-4.7,10-9.6,20.2c-7.9,16.3-18.6,38.5-22,46.6c-4.9,11.9-11.2,27.6-16.2,44.1c-1.2,3.9-4.2,6-10.2,6.9l-1.6,0.2l1.1-1.2
              c1-1.1,2-2.3,3-3.5l0.7-0.8c5.1-5.8,4.5-6.7,3.6-8.1l-0.2-0.3c-0.4-0.7-1.3-1-2.5-1c-6.4,0-19.9,8.2-27,13.1c-1.6,1.1-5.5,4-7.7,7.7
              c-3.2,5.3-3.9,10.2-2,14c0.6,1.2,2.8,2,5.5,2c0.5,0,1,0,1.5-0.1l1.5-0.2c7-0.9,13.6-1.7,22.6-3.2c0.7-0.1,1.4-0.2,2-0.2
              c1.6,0,2.6,0.4,3.2,1.3c0.8,1.1,0.3,2.5,0.3,2.6c0,0.4-0.1,0.9-0.2,1.4c-0.3,1.2-0.5,2.4-0.2,3.9c0.5,2.3,3.5,2.9,4.7,2.9L42,269
              v-0.7c2.2,0,6.8-1.7,12.4-13.2c0.9-1.9,1.7-2.8,4.9-3.4c0.3,0,25.1-4.6,34.2-6.7c14.9-3.6,30.8-9.3,48.6-17.4
              c44.1-20.2,57.1-49.6,57.2-49.9c2.5-6.8,1.7-12.5-2.5-17.5c-3.2-3.9-7.2-6.8-12-8.6c-12.9-5-26.3-5.8-39.3-6.6c0,0-9.2-0.5-13-0.8
              c-4.8-0.3-14.5-1.1-14.6-1.1l-1.4-0.1l1-0.9c2.8-2.5,5.7-4.9,8.5-7.4l0.9-0.8c5.5-4.7,11.2-9.6,16.7-14.7
              c11.4-10.6,26.4-24.7,41.3-39.4C197.8,67,206.4,56.2,212.8,45l0.4-0.7c2-3.6,4.6-8,4.9-13.3C218.1,30.1,217.7,27.1,215.2,24.4" />
      </clipPath>
    </defs>

    <path className={`${cl}__bg`}
      d="M236.3,173.7l-1,1.2c-0.5,0.6-1.9,2-3.9,3.9c-7.5,7.3-23,22.6-26.4,29.7c-2.2,4.6-2.9,7.2-3.1,11.6
          c-0.2,2.4,0.3,4.3,1.4,5.6c1.4,1.6,3.9,2.4,7.3,2.4c2.8,0,5.4-0.6,6.3-0.7c12.8-2.7,23-9.7,33.1-17.2c3.4-2.6,6.7-5.4,9.9-8.1
          c0.9-0.8,1.8-1.5,2.7-2.3l1.5-1.3l-0.5,1.9c0,0.1-2.3,8.1-3.2,11.7v0.2c-0.3,1.2,0,2.1,0.4,2.6c0.6,0.9,1.5,1.3,2.2,1.4
          c1.3,0.1,2.8-0.8,4.2-1.7c0.1-0.1,0.4-0.2,0.7-0.4c7.3-4.1,10.4-7.8,9.3-11.1c-1-2.9,0.3-5.3,1.7-7.8l0.3-0.5c0,0,3.6-6.6,5.2-9.4
          c1.7-3,3.5-6.6,5.2-9.7l0.9-1.6c0.7-1.3,0.4-3.3-0.6-4.5c-0.7-0.9-3.1-2-5.6-2c-2.1,0-3.9,0.7-5.4,2.2c0,0-17.1,17.7-21.1,21.9
          c-8.4,8.8-18.6,14.4-28.4,19.1c-1.9,0.9-4.6,2.1-6.9,2.1l0,0c-1.5,0-2.6-0.5-3.3-1.6c-3.1-4.9,7.4-15.3,13.1-20.9
          c0.9-0.8,1.6-1.5,2-2c1.4-1.5,4.5-4.6,7.5-7.6c3.2-3.2,6.5-6.5,7.2-7.3c1.8-2.1,3.7-4.2,5.1-6.4c1.1-1.7,1.2-3.5,0.4-4.6
          c-0.7-1-2-1.5-3.9-1.5s-4,0.4-6.2,0.9c-2.1,0.4-4.1,0.8-5.8,0.8h-0.3c-1.2,0-2.6-0.5-4-0.9c-1.5-0.5-3.1-1-4.7-1.1
          c-3.4-0.1-5.2,0.1-8.3,1.5c-12.8,5.5-28.7,30-29.7,31.6c-0.4,0.7-0.9,2.2-1.1,3.1c-0.1,0.8,0.1,2.9,1.3,3.5c0.9,0.4,1.8,0.7,2.7,0.7
          c4.9,0,9.6-6,11.7-8.5l0.4-0.5c0.5-0.6,11.5-15.2,17.5-18.7l0.2-0.1c0.6-0.2,1.6,0,2.9,0.2c1.5,0.3,3.3,0.6,5.5,0.6
          c0.8,0,1.5,0,2.3-0.1L236.3,173.7z M448,0c-7.9,0-15.4,3.4-22.7,10.5c-7.9,7.5-31.8,30.5-59.5,128.6c-1.6,5.5-3.2,11.1-4.9,17
          c-2.5,8.7-5.1,17.6-7.8,27.3c-5.3,19.1-9.8,36-13.9,51.6c-2.2,8.3-4.5,20.8-3,22.7c0.7,1,1.7,1.5,2.8,1.5c4.9,0,13-9.1,16.9-17.3
          c21.8-45.2,28-46.6,31.3-47.3c0.6-0.1,1.1-0.2,1.7-0.2c4.8,0,7.2,4.9,9.7,10c2.8,5.8,5.7,11.7,12.2,12.3c1.3,0.1,2.5,0.2,3.7,0.2
          c6.9,0,13.3-1.8,19.7-5.5c8.3-4.8,14.8-10.8,20-18.3c2.4-3.5,2.1-7.2,1.1-9.2c-0.6-1.2-1.5-2-2.3-2s-3.8,3-6.4,5.6
          c-6.2,6.1-14.6,14.5-21.3,14.5H425c-5.5-0.2-9.4-2.7-12.5-8.1c-0.1-0.2-0.3-0.5-0.5-0.9c-2.1-4-7.7-14.6-18.9-14.6
          c-6,0-11.2,2.1-15.9,6.5c-5.7,5.3-10.7,11.1-15.3,17.7l-1.9,2.8l0.9-3.3c29.7-113.3,49.6-150.2,53.3-156.5c0.4-0.7,4.2-7.4,9.7-14.1
          c7.7-9.3,14.7-14,20.9-13.7c12,0.5,15.2,11.6,16,18.2c1.6,12.9-1.3,26.1-9.2,41.4c-6.5,12.6-14.7,24.3-24.3,34.7
          c-1.6,1.7-4.8,4.8-8.6,8.4c-8.6,8.2-24.6,23.6-24.1,26.5c0.5,2.8,1.1,3,1.6,3c1,0,3.4,0,10-5.3c12.2-9.8,23.6-21,34.9-34.2
          c18.3-21.3,30.6-48.3,33.2-59.9c3-13.3,2.5-24.2-1.3-34.3c-2.7-6.9-8.2-12.3-14.8-14.6C454.7,0.7,451.3,0,448,0 M293.2,216.6
          c-3.7,0-62.7,27.4-77.7,39c-11.8,9.1-18.5,18.1-22.4,29.8c-5.3,15.8-6.5,31.2-3.7,46.9c4.4,25.4,15.5,44.6,32.8,56.8
          c13.1,9.2,27.4,12.5,35.6,12.5c0.3,0,0.6,0,0.8,0c15.9,0,33.7-3.9,45.1-32.5c8.4-21.5,16.2-69.4,21.9-134.9c0.1-1.2,0.2-2.1,0.2-2.7
          c1.3-14.6,4.6-25.7,6.6-32.4c0.8-2.8,1.4-4.9,1.5-5.9c0.1-1.4,0.1-2.7-1-4c-1-1.1-2.3-1.4-4.5-1.1c-1.3,0.2-2.4,0.6-3.6,1
          c-0.9,0.3-1.8,0.6-2.8,0.8c-5.7,1.3-9.5,0.9-10.9-1.3c-1-1.6-1.2-3.3-0.6-4.9c1.4-3.9,7.2-7.7,16.8-11.1l0.3-0.1
          c0.4-0.2,0.9-0.3,1.3-0.5c2.7-0.9,5.5-1.9,6.3-4c1-2.8,0.7-4.6-0.9-5.8c-0.6-0.4-2.3-1.4-6.1-1.4c-3.7,0-8.2,1-13.2,2.9
          c-17.6,7-20.4,23.7-20.8,28.7c-0.3,3.5,0.9,8.9,3.7,12c1.7,1.8,3.7,2.7,6.1,2.5c1.5-0.1,3.6-0.9,5.2-1.5c1.2-0.4,1.7-0.6,2.1-0.6
          h0.2l0.2,0.1c0.4,0.3,0.5,0.4-2,36.1l-0.5,7.2c-0.8,11.3-2.1,24.1-3.4,37.6c-0.7,7.1-1.4,14.5-2.1,21.6c-1.3,13.8-4.2,29.1-9.1,46.9
          c-3.5,13-14.9,24.9-26.4,27.8c-3.4,0.8-7.1,1.6-11.6,1.6c-13.8,0-26.9-7.8-39-23.1c-5.8-7.4-19.9-32.5-15.7-60.4
          c3.5-23.2,17.8-37.1,29.2-44.6c22.1-14.7,41.3-23.6,52.7-29c6.3-2.9,11.7-5.5,11.9-6.4c0-0.3-0.1-1.7-0.9-2.7
          C294.4,217,293.9,216.7,293.2,216.6 M296.5,145.7c-3.2,0-5.8,0.9-7.6,2.8c-3,3-3,7.6-3,7.6c0.1,0.9,0.3,1.6,0.8,2.1
          c1.3,1.4,4.3,2.1,6.5,2.1c1.1,0,2-0.2,2.7-0.6c3.8-2.2,6.8-4.1,9.4-5.9c1.1-0.7,1.8-2.4,1.6-3.8c-0.2-1.3-3-3.4-8.6-4.2
          C297.7,145.8,297.1,145.7,296.5,145.7 M502.3,75.1c-2.8,0-7,1.4-10.8,7.9c-4.2,7.2-11.1,30.3-19.4,65l-0.4,1.7l-0.4,0.1
          c-7.5,1-13.9,1.9-15.1,4.3c-0.1,0.4-0.1,0.8,0.2,1.1c1.6,2.3,9.6,3.1,12.5,3.2h0.7l-0.2,0.7c-1.2,5.3-2.3,10.1-3.2,14.8
          c-2.6,13-4.8,26.2-7,39c-2.1,8.9-4.2,23.6-1.2,25.2c1,0.5,1.9,0.8,2.8,0.8c2.9,0,4.9-2.6,6.4-4.6l0.5-0.6c5.4-6.8,7.1-22.8,8.7-38.3
          c1.1-10.8,2.3-21.9,4.7-30c0.5-1.7,1.7-7,1.7-7.1l0.1-0.4h0.4c0.6,0,63.6-4.5,75.3-5.2c13.6-0.8,52.5-3.4,58.2-3.9s7.6-1.1,8.6-2.3
          c0.5-1.4,0.5-2.6-1.4-4.3c-2-1.8-4.7-3.5-8-5c-3.2-1.5-6.7-2.9-10.6-3.7c-1.4-0.3-3.2-0.4-5.4-0.4c-10.1,0-26.5,3-36.3,4.7
          c-1.9,0.3-3.6,0.7-5,0.9c-3.6,0.6-5.7,1-7.6,1.4c-3.2,0.6-5.5,1-13.4,2.2c-9.6,1.5-32.8,4.1-52,6.3l-0.9,0.1l0.2-0.8
          c3.7-15.6,7.5-29.8,11.5-43.4c0.1-0.5,3.4-12,9.5-17.6c2.8-2.5,4.3-4.7,3.3-7.5C509.3,77.7,506.3,75.1,502.3,75.1 M198.3,44.8
          c-2.3,4.6-13.6,20.3-25.9,32.4c-0.2,0.2-20.5,20.2-30.7,29.5c-13.2,12-38.5,31.4-41.6,32l-1,0.2l0.4-1c0.1-0.3,0.3-0.9,0.6-1.8
          c1.7-5.1,5.8-17,9.1-22.7c7.7-13.1,19.2-30.3,39.2-47.3c20.4-17.2,35.6-26.4,45.1-27.2c2.9-0.2,4.2,0.9,4.8,1.8
          C199,41.8,199,43.4,198.3,44.8 M150.8,163.7h1c18.4,0,29.4,9,30.4,14c2.8,14.7-46.3,38-65.4,44.3c-20.6,6.9-46.4,10.8-50.8,10.8
          c-0.5,0-0.8-0.1-0.9-0.1l-0.2-0.1l-0.1-0.3c-0.1-0.4-0.3-1.3,13.3-37.2c6.3-16.7,13.5-35.5,13.6-35.7l0.7-0.3
          C104.5,163.5,143.1,163.7,150.8,163.7 M215.2,24.4c-4.1-4.4-11.6-5.4-21.7-2.8c-14.1,3.6-25.1,12.3-35.7,20.6c-1,0.8-2,1.6-3,2.3
          c-16.3,12.7-39.3,41.3-41.6,44.2l0.2,0.6l-1-0.1l-0.2-0.1c-0.9-0.6-3.3-2-5.9-2c-2.7,0-9,1.7-10.7,15.9c-0.1,1.1-2.3,12.9-4,17.1
          c-0.8,1.9-4.7,10-9.6,20.2c-7.9,16.3-18.6,38.5-22,46.6c-4.9,11.9-11.2,27.6-16.2,44.1c-1.2,3.9-4.2,6-10.2,6.9l-1.6,0.2l1.1-1.2
          c1-1.1,2-2.3,3-3.5l0.7-0.8c5.1-5.8,4.5-6.7,3.6-8.1l-0.2-0.3c-0.4-0.7-1.3-1-2.5-1c-6.4,0-19.9,8.2-27,13.1c-1.6,1.1-5.5,4-7.7,7.7
          c-3.2,5.3-3.9,10.2-2,14c0.6,1.2,2.8,2,5.5,2c0.5,0,1,0,1.5-0.1l1.5-0.2c7-0.9,13.6-1.7,22.6-3.2c0.7-0.1,1.4-0.2,2-0.2
          c1.6,0,2.6,0.4,3.2,1.3c0.8,1.1,0.3,2.5,0.3,2.6c0,0.4-0.1,0.9-0.2,1.4c-0.3,1.2-0.5,2.4-0.2,3.9c0.5,2.3,3.5,2.9,4.7,2.9L42,269
          v-0.7c2.2,0,6.8-1.7,12.4-13.2c0.9-1.9,1.7-2.8,4.9-3.4c0.3,0,25.1-4.6,34.2-6.7c14.9-3.6,30.8-9.3,48.6-17.4
          c44.1-20.2,57.1-49.6,57.2-49.9c2.5-6.8,1.7-12.5-2.5-17.5c-3.2-3.9-7.2-6.8-12-8.6c-12.9-5-26.3-5.8-39.3-6.6c0,0-9.2-0.5-13-0.8
          c-4.8-0.3-14.5-1.1-14.6-1.1l-1.4-0.1l1-0.9c2.8-2.5,5.7-4.9,8.5-7.4l0.9-0.8c5.5-4.7,11.2-9.6,16.7-14.7
          c11.4-10.6,26.4-24.7,41.3-39.4C197.8,67,206.4,56.2,212.8,45l0.4-0.7c2-3.6,4.6-8,4.9-13.3C218.1,30.1,217.7,27.1,215.2,24.4" />

    <polyline clipPath={`url(#bb_logo_clippath--${type})`} className={`${cl}__pen ${cl}__pen--b`} points="39.7,270 63.6,206.4 76.9,172 85.8,149.2 96.2,123.3 106.8,99.4 112.2,89.1 130.1,73.9 
          163.5,45.8 189.2,31.8 207.4,30.7 212.1,30.7 205.2,48 185.9,71.7 155.1,104.9 125.9,128 100.9,150.3 111.9,153 133,154.1 
          160,155.9 175.1,159.1 183.3,161.9 190.5,167 189.1,174.4 176.4,195.9 140.7,218.8 99.1,233.9 60.3,243.6 17.3,250.2 3.4,250.9 
          18.4,235.2 33.1,227.2 36.6,219 " />
    <path clipPath={`url(#bb_logo_clippath--${type})`} className={`${cl}__pen ${cl}__pen--ri`} d="M192.3,202.8l16.8-21.7l13.1-14.6l8.4-2l10,2l12.4-3.1l-25.3,28.1l-15.7,16.8l-4.9,14.4l16.6-4.7
          l26.1-15.7l18.8-15.9l10.4-12.4c2-1.7,5,0.1,4.4,2.7l-9.2,19.2l-7.5,16.2l-7.4,4.2" />
    <path clipPath={`url(#bb_logo_clippath--${type})`} className={`${cl}__pen ${cl}__pen--dot`}
      d="M284.6,157.3c12-6.2,22.3-9.8,22.3-9.8" />
    <path clipPath={`url(#bb_logo_clippath--${type})`} className={`${cl}__pen ${cl}__pen--g`}
      d="M335.7,164.5l-19.3,6.5l-10.5,8c-2.7,2.1-4.3,5.3-4.3,8.7v11l5.4,1.3l16.9-6.9c1.8-0.6,3.5,1,3,2.8
          l-8.3,20.5l-3.7,34.6l-3.1,45l-5.5,36.1l-8.6,35l-13.9,17.7c-0.9,1.2-2.1,2.2-3.6,2.7l-11.9,4.7c-1.3,0.5-9.9,1.3-9.9,1.3l-22.2-4.4
          l-17.9-15.7l-16.2-23l-6.6-21.3v-24.4l4.7-20.4l10.9-15.1l16.8-14.8l15.3-10.4l15.9-4.8l18.4-7.6l16.4-12.6l2.4-2.2" />
    <path clipPath={`url(#bb_logo_clippath--${type})`} className={`${cl}__pen ${cl}__pen--h`} d="M392.6,150.3l36.8-33.7l19.5-25.3l14-26.4l6.9-22.6l-1.6-12.8l-4.7-12.8l-3.5-2.2
          c0.4-2.7-4.2-3.8-5.3-4.1l-10.2-2.5l-12.2,6.4L421.4,25l-13.1,18.8l-12.2,25l-9.1,24.6l-10,27.9l-8.6,23l-6.9,26.8l-5.8,19.7
          l-8.4,45.2l-4.7,15.5l-3.3,5.3l-2,2.3l34.8-60.1l18.8-15.7l10.3,7.5l7.6,13.3l3.5,6.2l20-3.8l12.8-12.2l8.9-14.4" />
    <polyline clipPath={`url(#bb_logo_clippath--${type})`} className={`${cl}__pen ${cl}__pen--t1`}
      points="508,72.8 492.3,99.8 483,129.6 474.8,169.4 471.5,198.9 462.6,230.5 460.8,238.9 " />
    <polyline clipPath={`url(#bb_logo_clippath--${type})`} className={`${cl}__pen ${cl}__pen--t2`}
      points="453.7,154.5 521.5,149.7 566.7,145 602.2,140.4 617.4,143.9 623.4,145 625.4,146.5 " />
  </svg>
);

export default ClipPathLogo;
